define('app/features/infiniteloader',["app/config","jquery","app/features/spinners","waypoints"], function(config,$,spinners) {
    return {

        feature: true,

        instances: [],

        spinnerTpl: spinners.get('prizeware'), // TODO - Set a more generic default spinner

        addInstance: function(config) {
            this.cleanupInstances();
            this.instances.push($.extend({
                remote: false,
                spinner: true,
                template: '<div class="text-center">Loading...</div>',
                pageSize: 25,
                selector: null,
                // Internal props
                loading: false,
                currentPage: 1,
                waypoint: null,
                totalCount: null,
                dataset: null
            },config));
            var instance = this.getInstance(config.el);
            var waypoint = $(config.el).waypoint($.proxy(function(){
                if(!instance.loading) {
                    instance.loading = true;
                    this.checkLoadMore(instance);
                }
            },this),{
                context: parent.window,
                offset: function() {
                    return -($(this.element).height()-$(parent.window).height()); // TODO - Cross browser test this
                }
            });
            instance.waypoint = waypoint[0];
            if(!instance.remote) {
                instance.dataset = $(config.el).find(instance.selector).clone();
                instance.totalCount = $(instance.dataset).length;
                $(instance.el).find(instance.selector).slice(instance.pageSize).remove();
                instance.waypoint.context.refresh();
            }
        },

        removeInstance: function(el) {
            var instance = this.getInstance(el);
            if(instance) {
                instance.waypoint.destroy();
                // TODO - Pop off array
            }
        },

        cleanupInstances: function(el) {
            $.each(this.instances,$.proxy(function(index,value){
                if($(el).find($(value.el)).length > 0) {
                    this.removeInstance(value.el);
                }
            },this));
        },

        getInstance: function(el) {
            var found = null;
            $.each(this.instances,function(){
                if($(this.el).eq($(el))) {
                    found = this;
                }
            });
            return found;
        },

        checkLoadMore: function(instance) {
            if(instance) {
                this.appendLoadEl(instance);
                if(instance.remote) {
                    instance.remote(instance,$.proxy(function(finished){
                        $(instance.el).find('.loadSpinner').remove();
                        if(finished) {
                            this.removeInstance(instance.el);
                        } else {
                            instance.waypoint.context.refresh();
                        }
                        setTimeout($.proxy(function(){
                            instance.loading = false;
                        },this),1000);
                    },this));
                } else {
                    if(instance.currentPage*instance.pageSize > instance.totalCount) {
                        this.removeInstance(instance.el);
                    } else {
                        var els = $(instance.dataset).slice(instance.currentPage*instance.pageSize,(instance.currentPage*instance.pageSize)+instance.pageSize);
                        $(instance.el).find(instance.selector).last().after(els);
                        instance.waypoint.context.refresh();
                        instance.currentPage++;
                        instance.loading = false;
                    }
                }
            }
        },

        appendLoadEl: function(instance) {
            if(instance.remote) {
                if (instance.template) {
                    $(instance.el).append(instance.template);
                }
                if (instance.spinner) {
                    $(instance.el).append(this.spinnerTpl);
                }
            }
        }
    }
});
