define('modules/process/slidecard/js/base.js',["app/module","app/config","jquery","app/util","templates","swiper.jquery.min","jquery.livequery"], function(module,config,$,util,templates) {
    return new module({
        name: 'process.slidecard',
        selectors: '.process-slidecard-wrap',
        remoteData: [(config.urls.process)],
        
        config: {
            
        },

        routes: [{
            route: 'home',
            extra: true,
            delay: 2000,
            fn: 'initSwiper' // Route fallback method, will typically be handled via setInterval on onRender method
        }],

        initialize: function (data) {
            this.data = data;
            this.renderTemplates(this.data);
        },

        onRender: function() {
            util.setVisibleInterval($(this.getSelectors()),$.proxy(this.initSwiper,this),1000);  // Swiper will not properly initialize until the element is actually visible on DOM, hence this interval

            // background animation used in lahc2017
            // util.setVisibleInterval($(this.getSelectors()),$.proxy(this.attachProcessInkResizeEvent,this),1000);
        },

        methods: {

            renderTemplates: function (data) {
                $(this.getSelectors()).append(templates.processslidecard({
                    steps: data.sort(function(a, b) {
                        return a.id - b.id;
                    })
                }));
            },

            initSwiper: function() {

                if(this.swiperInstance) {
                    return;
                }

                this.swiperInstance = new Swiper('.swiper-main .swiper-container-process', {
                    slidesPerView: 3,
                    // centeredSlides: true,
                    spaceBetween: 25,
                    loop: false,
                    //next lines remove the touch functionality and the mousedrag (simulating touch)
                    simulatesTouch: false,
                    shortSwipes: false,
                    longSwipes: false,
                    onlyExternal: true,
                    prevButton: false,
                    nextButton: false,
                    // pagination
                    pagination: false,
                    preventClicks: false,
                    preventClicksPropagation: false,
                    breakpoints: {
                        1366: {
                            slidesPerView: 2
                        },
                        768: {
                            slidesPerView: 1,
                            prevButton: ".swiper-main .process-swiper-navigate-prev",
                            nextButton: ".swiper-main .process-swiper-navigate-next",
                            pagination: '.swiper-main .process-swiper-pagination',
                            simulatesTouch: true,
                            shortSwipes: true,
                            longSwipes: true,
                            onlyExternal: false,
                        }
                    }
                });

                this.swiperInstanceSecondary = new Swiper('.swiper-secondary .swiper-container-process', {
                    slidesPerView: 3,
                    centeredSlides: true,
                    spaceBetween: 25,
                    loop: false,
                    //next lines remove the touch functionality and the mousedrag (simulating touch)
                    simulatesTouch: false,
                    shortSwipes: false,
                    longSwipes: false,
                    onlyExternal: true,
                    prevButton: ".swiper-secondary .process-swiper-navigate-prev",
                    nextButton: ".swiper-secondary .process-swiper-navigate-next",
                    // pagination
                    pagination: '.swiper-secondary .process-swiper-pagination',
                    preventClicks: false,
                    preventClicksPropagation: false,
                    breakpoints: {
                        1366: {
                            slidesPerView: 2
                        },
                        768: {
                            slidesPerView: 1,
                            pagination: '.swiper-secondary .process-swiper-pagination',
                            simulatesTouch: true,
                            shortSwipes: true,
                            longSwipes: true,
                            onlyExternal: false,
                        }
                    }
                });

                this.swiperInstanceSecondary.on('slideNextStart', $.proxy(function() {
                    var activeSlide = this.swiperInstanceSecondary.activeIndex,
                        swiperMain = $('.swiper-main');

                    if (activeSlide > 1) {
                        this.swiperInstance.slideNext();
                    }

                    swiperMain.find('.swiper-slide-active').removeClass('swiper-slide-active');
                    swiperMain.find('.swiper-slide').eq(activeSlide).addClass('swiper-slide-active');
                }, this));

                this.swiperInstanceSecondary.on('slidePrevStart', $.proxy(function() {
                    var activeSlide = this.swiperInstanceSecondary.activeIndex,
                        swiperMain = $('.swiper-main');

                    // TODO this should be relative to the amount of cards we have - 2
                    if (activeSlide < 6) {
                        this.swiperInstance.slidePrev();
                    }

                    swiperMain.find('.swiper-slide-active').removeClass('swiper-slide-active');
                    swiperMain.find('.swiper-slide').eq(activeSlide).addClass('swiper-slide-active');
                }, this));

                // mobile fraction pagination
                var swiperMainPaginationFraction = $('.swiper-main .process-swiper-pagination-fraction'),
                    slidesNum = $('.swiper-main').find('.swiper-slide').length,
                    activeSlide = this.swiperInstance.activeIndex;

                swiperMainPaginationFraction.find('.all').text(slidesNum);

                parseInt(activeSlide);
                activeSlide++;

                swiperMainPaginationFraction.find('.current').text(activeSlide);

                this.swiperInstance.on('slideChangeStart', $.proxy(function() {
                    activeSlide = this.swiperInstance.activeIndex;

                    parseInt(activeSlide);
                    activeSlide++;

                    swiperMainPaginationFraction.find('.current').text(activeSlide);
                }, this));


                // this.swiperInstance.params.control = this.swiperInstanceSecondary;
                // this.swiperInstanceSecondary.params.control = this.swiperInstance;

                //process animations demo for lahc2017 project
                // var processSwiper = $('.swiper-container-process')[0].swiper;

                // var processSlidecardWrap = $('.section.home-process'),
                //     activeSlide;

                // processSlidecardWrap.find('.ink-slide:first-child').addClass('process-ink-animated');

                // processSwiper.on('slideNextStart', function() {
                //     activeSlide = processSwiper.activeIndex;
                //     processSlidecardWrap.find('.process-ink-animated').removeClass('process-ink-animated process-ink-animated-prev process-ink-animated-next');
                //     processSlidecardWrap.find('.ink-slide-' + activeSlide).addClass('process-ink-animated process-ink-animated-next');
                // });

                // processSwiper.on('slidePrevStart', function() {
                //     activeSlide = processSwiper.activeIndex;
                //     activeSlide = parseInt(activeSlide);
                //     activeSlide = activeSlide + 1;
                //     processSlidecardWrap.find('.process-ink-animated').removeClass('process-ink-animated process-ink-animated-prev process-ink-animated-next');
                //     processSlidecardWrap.find('.ink-slide-' + activeSlide).addClass('process-ink-animated process-ink-animated-prev');
                // });
            },

            attachProcessInkResizeEvent: function() {

                //keeping proper dimensions for the .page-ink-layer on windows resize - landscape or portrait
                var inkElement = $('.resizable-ink-element-to-parent'),
                    frameProportion = inkElement.attr('data-ink-framerate'), // this is the proportion of a single frame from the png - width/height
                    frameProportion = parseFloat(frameProportion),
                    frames = inkElement.attr('data-ink-frames'), // number of frames
                    frames = parseInt(frames),
                    inkElementParent = inkElement.closest('.resizable-ink-element-parent'),
                    parentWidth,
                    parentHeight,
                    inkLayerWidth,
                    inkLayerHeight;

                function updateProcessInkSize() {

                    parentWidth = inkElementParent.width();
                    parentHeight = inkElementParent.height();

                    if( parentWidth/parentHeight > frameProportion ) {
                        inkLayerWidth = parentWidth+17;
                        inkLayerHeight = inkLayerWidth/frameProportion;
                    } else {
                        inkLayerHeight = parentHeight;
                        inkLayerWidth = inkLayerHeight*frameProportion;
                    };

                    inkElement.css({
                        'width': inkLayerWidth*frames+'px',
                        'height': inkLayerHeight+'px',
                    });
                };

                updateProcessInkSize();

                $(window).on('resize', updateProcessInkSize);
            }
        }
    });
});

