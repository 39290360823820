define('modules/searchabledatabase/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "app/features/infiniteloader", "bootstrap/dropdown", "bootstrap/select"], function (module, config, $, util, templates, infiniteloader) {
    return new module({
        name: 'searchabledatabase',
        selectors: '.searchableDatabase',
        remoteData: [config.urls.searchabledatabase],

        routes: [{
            route: 'explore-submissions',
            extra: true
        }],

        initialize: function (data) {
            this.data = (data || this.data);
            this.searchCount = 1;

            this.checkTerms(this.data);
        },

        methods: {

            loadSearch: function () {
                $(this.getSelectors()).html(templates.searchabledatabasepage({
                    config: config
                }));

                $(this.getSelectors()).find('.selectpicker').selectpicker({
                    liveSearch: true
                });

                this.initClickHandlers();
                this.initDropDownSearch();

            },


            initClickHandlers: function () {
                var module = this;

                $(this.getSelectors()).find('.searchButton').on('click', $.proxy(this.performSearch, this));

                $(this.getSelectors()).find('.resetButton').on('click', $.proxy(this.resetSearch, this));

                $(this.getSelectors()).find('.load-more-results').on('click', $.proxy(this.loadMore, this));

            },

            initDropDownSearch: function () {
                var module = this;
                var searchType;
                var searchTerm;


                // org name
                $(this.getSelectors()).find('.organizationName-select .bs-searchbox input').keyup(function (e) {

                    // keycodes from 37 to 40 are used for the arrow keys
                    if (e.keyCode != 37 && e.keyCode != 38 && e.keyCode != 39 && e.keyCode != 40) {
                        searchTerm = this.value;
                        searchType = "organizationName";

                        if (this.value !== '') {
                            module.populateDropdowns(searchTerm, searchType);
                        }
                    }
                });

                // location
                $(this.getSelectors()).find('.location-select .bs-searchbox input').keyup(function (e) {

                    // keycodes from 37 to 40 are used for the arrow keys
                    if (e.keyCode != 37 && e.keyCode != 38 && e.keyCode != 39 && e.keyCode != 40) {
                        searchTerm = this.value;
                        searchType = "location";

                        if (this.value !== '') {
                            module.populateDropdowns(searchTerm, searchType);
                        }
                    }
                });
            },

            populateDropdowns: function (searchTerm, searchType) {

                let tempData = this.data;

                let orgOptions = [];

                if (searchTerm) {
                    let typedTerm = '<option value="' + searchTerm + '">' + searchTerm + '</option>';
                    orgOptions.push(typedTerm);
                }

                let tempOptions = [];

                if (searchTerm && searchType === 'organizationName') {
                    tempData.filter(element => {

                        if (element.OrganizationName.toLowerCase().includes(searchTerm)) {
                            tempOptions.push(element.OrganizationName);
                        }
                    });
                }

                if (searchTerm && searchType === 'location') {
                    tempData.filter(element => {
    
                        if (element.details.Location.toLowerCase().includes(searchTerm)) {
                            tempOptions.push(element.details.Location);
                        }
                    });
                }

                for (let i = 0; i < tempOptions.length; i++) {
                    let option = '<option value="' + tempOptions[i] + '">' + tempOptions[i] + '</option>';
                    orgOptions.push(option);
                }

                $(this.getSelectors()).find('.' + searchType + '-select .selectpicker').html(orgOptions);
                $(this.getSelectors()).find('.' + searchType + '-select .selectpicker').selectpicker('refresh');

            },

            performSearch: function (e) {
                var module = this;

                e.preventDefault();

                this.searchCount = 1;

                var searchTitle = $(this.getSelectors()).find('.results-container-title');

                if (!searchTitle.hasClass('colorful')) {
                    searchTitle.addClass('colorful');
                };

                $(this.getSelectors()).find('.load-more-results').hide();

                var searchTerm = $(this.getSelectors()).find('.searchInput').val().toLowerCase();
                var OrganizationNameTerm = $(this.getSelectors()).find('select[name="organization"]').val().toLowerCase() || '';
                var LocationTerm = $(this.getSelectors()).find('select[name="location"]').val().toLowerCase() || '';

                var tempData = this.data.filter(element => {
                    if (OrganizationNameTerm) {
                        if (!element.OrganizationName.toLowerCase().includes(OrganizationNameTerm)) {
                            return false;
                        }
                    };

                    if (LocationTerm) {
                        if (!element.details.Location.toLowerCase().includes(LocationTerm)) {
                            return false;
                        }
                    };

                    if (searchTerm) {
                        if (!element.details.PropolsalTitle.toLowerCase().includes(searchTerm) && 
                            !element.details.QuickPitch.toLowerCase().includes(searchTerm) && 
                            !element.details.TheProblem.toLowerCase().includes(searchTerm) && 
                            !element.details.OurApproach.toLowerCase().includes(searchTerm) && 
                            !element.OrganizationName.toLowerCase().includes(searchTerm)) {
                            return false;
                        }
                    };
                    return true;
                });

                if (tempData.length > 20) {
                    $(this.getSelectors()).find('.load-more-results').show();
                    tempData = tempData.slice(0, 20);
                } else {
                    $(this.getSelectors()).find('.load-more-results').hide();
                }

                if (tempData.length > 0) {
                    $(this.getSelectors()).find('.resultsContainer').html(templates.searchabledatabaselisting({
                        response: tempData,
                        config: config
                    }));
    
                    $(this.getSelectors()).find('.expand-result').on('click', function () {
                        var self = $(this),
                            searchResultContainer = self.closest('.search-result'),
                            resultId = self.attr('data-resultid');
    
                        if (searchResultContainer.hasClass('result-expanded')) {
                            module.closeDetails(resultId);
                            setTimeout(function () {
                                searchResultContainer.removeClass('result-expanded');
                            }, 400);
                        } else {
                            module.getDetails(resultId);
                            searchResultContainer.addClass('result-expanded');
                        }
                    });
                } else {
                    $(this.getSelectors()).find('.resultsContainer').html(templates.searchabledatabasenoresults());
                }

                $('html,body').animate({
                    scrollTop: $('.results-container').offset().top - 100
                }, 1000);
            },

            loadMore: function (e) {
                var module = this;

                e.preventDefault();
                this.searchCount++;

                var searchTerm = $(this.getSelectors()).find('.searchInput').val().toLowerCase();
                var OrganizationNameTerm = $(this.getSelectors()).find('select[name="organization"]').val().toLowerCase() || '';
                var LocationTerm = $(this.getSelectors()).find('select[name="location"]').val().toLowerCase() || '';

                var tempData = this.data.filter(element => {
                    if (OrganizationNameTerm) {
                        if (!element.OrganizationName.toLowerCase().includes(OrganizationNameTerm)) {
                            return false;
                        }
                    };

                    if (LocationTerm) {
                        if (!element.details.Location.toLowerCase().includes(LocationTerm)) {
                            return false;
                        }
                    };

                    if (searchTerm) {
                        if (!element.details.PropolsalTitle.toLowerCase().includes(searchTerm) && 
                            !element.details.QuickPitch.toLowerCase().includes(searchTerm) && 
                            !element.details.TheProblem.toLowerCase().includes(searchTerm) && 
                            !element.details.OurApproach.toLowerCase().includes(searchTerm) && 
                            !element.OrganizationName.toLowerCase().includes(searchTerm)) {
                            return false;
                        }
                    };
                    return true;
                });

                let noMoreResultsShow = false;
                if (tempData.length < 20*this.searchCount) {
                    noMoreResultsShow = true;
                }

                if (tempData.length > 20*this.searchCount) {
                    $(this.getSelectors()).find('.load-more-results').show();
                    tempData = tempData.slice(20*this.searchCount - 20, 20*this.searchCount);
                } else {
                    $(this.getSelectors()).find('.load-more-results').hide();
                }

                if (tempData.length > 0) {
                    $(this.getSelectors()).find('.resultsContainer').append(templates.searchabledatabaselisting({
                        response: tempData,
                        config: config
                    }));

                    if (noMoreResultsShow) {
                        $(this.getSelectors()).find('.resultsContainer').append('<span class="no-more-results">No More Results</span>');
                    }
    
                    $(this.getSelectors()).find('.resultsContainer .container:last-child .expand-result').on('click', function () {
                        var self = $(this),
                            searchResultContainer = self.closest('.search-result'),
                            resultId = self.attr('data-resultid');
    
                        if (searchResultContainer.hasClass('result-expanded')) {
                            module.closeDetails(resultId);
                            setTimeout(function () {
                                searchResultContainer.removeClass('result-expanded');
                            }, 400);
                        } else {
                            module.getDetails(resultId);
                            searchResultContainer.addClass('result-expanded');
                        }
                    });
                } else {
                    $(this.getSelectors()).find('.resultsContainer').html(templates.searchabledatabasenoresults());
                }
            },

            getDetails: function (resultId) {

                var tempData = this.data.filter(element => {
                    if (element.Id === resultId) {
                        return element;
                    };
                });

                // tempdata here is array and not an object, thus the following conversion
                if (tempData.length > 0) {
                    var resultDestination = $(this.getSelectors()).find('.expanded-result[data-resultid="' + resultId + '"]');
                    resultDestination.prev('.search-result').addClass('result-expanded');
                    resultDestination.addClass('expanded').slideDown(300);

                    if (tempData[0].Website) {
                        if (tempData[0].details.Website.startsWith('http')) {
                        } else {
                            tempData[0].details.Website = 'http://' + tempData[0].Website;
                        }
                    }

                    resultDestination.html(templates.searchabledatabaseexpandedresult(tempData[0]));
                };
            },

            closeDetails: function (resultId) {
              var resultDestination = $(this.getSelectors()).find('.expanded-result[data-resultid="' + resultId + '"]');
              resultDestination.removeClass('expanded').slideUp(300, function() {
                resultDestination.html('');
              });
            },

            resetSearch: function (e) {
                e.preventDefault();
                $(this.getSelectors()).find('.load-more-results').hide();
                $(this.getSelectors()).find('.searchInput').val('');
                $(this.getSelectors()).find('select').selectpicker('val', '');
                $(this.getSelectors()).find('.select-container .selectpicker').html('');
                $(this.getSelectors()).find('.select-container .selectpicker').selectpicker('refresh');
                $(this.getSelectors()).find('.resultsContainer').html(templates.searchabledatabaseloading);
                $(this.getSelectors()).find('.sample-results').removeClass('show');
                $(this.getSelectors()).find('.results-loader').css('width', '0%');
                $(this.getSelectors()).find('.results-container-title').removeClass('colorful')
            },

            checkTerms: function () {

                var module = this;

                if (parseInt($.cookie('sdb-agr'))) {
                    this.loadSearch();
                }
                else {
                    $(this.getSelectors()).html(templates.licenseagreement({
                        config: config
                    }));

                    $(this.getSelectors()).find('.license-agreement-confirm').click(function () {
                        $("html, body").animate({ scrollTop: 0 }, 400);
                        var date = new Date();
                        date.setTime(date.getTime() + (12 * 60 * 60 * 1000)); // Expires in 12 Hours
                        $.cookie('sdb-agr', 1, {expires: date});
                        module.loadSearch();
                    });
                }

            },

            playVideo: function (e) {
                e.preventDefault();
                $('body').addClass('locked');
                var youtubeId = $(e.currentTarget).attr('data-videoid');
                $(this.getSelectors()).find('.video-container iframe').attr('src', 'https://www.youtube.com/embed/' + youtubeId + '?rel=0&autoplay=1');
                $(this.getSelectors()).find('.search-results-video-wrapper').fadeIn();
                $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');
            },

            stopVideo: function () {
                $('body').removeClass('locked');
                $(this.getSelectors()).find('.search-results-video-wrapper').fadeOut();
                $(this.getSelectors()).find('.video-container iframe').attr('src', '');

            }
        }
    });
});
