define('modules/promovideoslider/js/base.js',["app/config", "jquery", "app/util", "templates", "app/features/video"], function (config, $, util, templates) {
    return {
        name: 'promovideoslider',
        selectors: '.promoVideoSliderWrap',
        remoteData: [],
        routes: [{
            route: '.*',
            fn: function () {
                $('.js-close-video-overlay').trigger('click');
            },
            extra: true
        }],

        initialize: function (data) {

            data = (data || []);


            $(this.selectors).append(templates.promovideoslider({
                records: data
            }));

            if (data.length > 0) {
                $('#promoVideoSlider').carousel({
                    interval: 8000,
                    wrap: true
                });
                var totalItems = data.length;
                // init position counter
                if (totalItems <= 1) {
                    $('.video-slider-controls .controls-container').hide();
                }
                $('.promoVideoSliderPosition').html('1 / ' + totalItems + '');

                $('#promoVideoSlider').on('slid.bs.carousel', function () {
                    currentIndex = $('#promoVideoSlider div.active').index() + 1;
                    $('.promoVideoSliderPosition').html('' + currentIndex + ' / ' + totalItems + '');
                });
            }

            this.initClickHandlers(data);
        },

        // TODO this needs heavy refactoring
        initClickHandlers: function (data) {

            var modalEl = $('#promoVideoModal');

            $('body').livequery('.video-modal-fullscreen', function () {

                $('.video-modal-fullscreen').on('click', function () {

                    $('body').addClass('video-open locked');
                    $('.homeVideoModal').fadeIn();

                    if ($('.videoBackground').find('video').length) {
                        $('.videoBackground').find('video').get(0).pause();
                    }

                    var videoUrl = $(this).data('video-url');
                    var sources = [];

                    modalEl.find('.modal-content').html(
                        '<div class="custom-video" data-youtube="lSBsmDwaCcc"></div>'
                    );

                    // Ready
                    $(modalEl).find('.custom-video').on('videoready', function () {

                    });


                    // vid complete
                    $(modalEl).find('.custom-video').on('videoended', function () {
                        $('#promoVideoSliderContainer .video-controls').fadeIn();

                        // TODO this is for custom buttons but we
                        // don't have them on this project
                        //$('#promoVideoModal .btn-container').show().fadeIn();

                        $('#promoVideoModal .btn.video-btn.rewatch').show().removeClass('fadeOutUp').addClass('animated fadeInDown').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {

                        });

                        $('#promoVideoModal .btn.video-btn.register').show().removeClass('fadeOutUp').addClass('animated fadeInDown').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {

                        });
                    });


                    modalEl.modal({
                        backdrop: false
                    });

                    $('#promoVideoModal .btn-container').fadeOut(); // TODO - This only applies if no action buttons are present

                    modalEl.on('hidden.bs.modal', function (event) {
                        $('#promoVideoModal .btn-container').fadeOut();
                        $('body').removeClass('video-open locked');
                        $('.navbarWrap').removeClass('slideUpOut').addClass('slideDownIn');
                        $('.gif-logo').fadeIn();

                        //videoEl.pause();
                        modalEl.find('.custom-video').remove();

                    });
                });

            });

            $(document).keyup(function (e) {
                if (e.keyCode == 27) {
                    $('.js-close-video-overlay').trigger('click');
                }
            });

            $('.js-close-video-overlay').on('click', function () {

                modalEl.find('.modal-content').empty();

                $('body').removeClass('video-open locked modal-open');
                $('.homeVideoModal').fadeOut();

                if ($('.videoBackground').find('video').length) {
                    $('.videoBackground').find('video').get(0).play();
                }
            })
        }
    }
});

