define('modules/finalists/gridoverlay/js/base.js',[
    'app/config', 'jquery', 'templates', 'skrollr', 'waypoints', 'app/features/video'
], function (config, $, templates, skrollr) {
    return {
        name: 'finalists',
        selectors: '.finalistContainer, .granteeContainer',
        remoteData: [config.urls.finalists],

        routes: [
            {
                route: 'finalists',
                fn: function (hash) {
                    this.closeAllOverlays(hash);
                },
                extra: true,
                delay: 500
            }, {
                route: 'finalists/(.*)',
                fn: function (hash) {
                    this.goToHash2(hash);
                },
                extra: true,
                delay: 2000
            }, {
                route: 'grantees',
                fn: function (hash) {
                    this.closeAllOverlays(hash);
                },
                extra: true,
                delay: 500
            }, {
                route: 'grantees/(.*)',
                fn: function (hash) {
                    this.goToHash2(hash);
                },
                extra: true,
                delay: 2000
            },
            {
                route: '.*',
                fn: function () {
                    this.resetGranteesVideo();
                },
                extra: true,
                delay: 250
            }
        ],
        initialize: function (data) {
            var initHtml;

            this.data = (data || this.data);
            this.renderTemplates(this.data);
            //this.finalistOrginalData(this.data);
            //this.finalistTabHandler(this.data);
            //this.refreshFinalistSlider(data);
            this.finalistOverlays(data);

        },

        closeAllOverlays: function (hash) {

            if ($('.finalist-overlay-grid').hasClass('open')) {
                $('.finalistContainer .close-overlay')[0].click();
                $('.granteeContainer .close-overlay')[0].click();
            }

        },

        goToHash2: function (hash) {
            var scrollToTeam = hash.split('/')[1];
            //console.log(scrollToTeam);

            $(".intro-section-btn[data-scrollto='.section.finalists']").trigger('click');

            var finalistItem = $('.finalist-post[data-teamid="' + scrollToTeam + '"]');
            if (finalistItem) {

                var innerCol = finalistItem.find('.inner-col');
                $(innerCol).trigger('tap');

            }
        },

        goToHash: function (hash) {
            var scrollToTeam = hash.split('/')[1];

            if (scrollToTeam <= 5) {

                $('.finalist-page .intro-section.main').hide();
                $('.finalist-overview').hide();
                $('.participant-overlay-grid').hide();

                $('html,body').scrollTop(0);

                $('.finalist-overlay-grid').fadeIn(500).addClass('open');
                $('.intro-section-container').fadeIn(500);
                $('.intro-section-container .finalist').fadeIn(500);

                setTimeout(function () {
                    $('html, body').animate({
                        scrollTop: $('.finalist-container[data-finalist="' + scrollToTeam + '"]').offset().top - 50
                    }, 1000);
                    $('body').trigger('pagenavupdate');
                }, 2000);

                $('body').trigger('pagenavupdate');
                $('.finalist-page .sideNavWrap').fadeIn();
                $('.finalist-page .closeNav').fadeIn();

            } else {

                $('.finalist-page .intro-section.main').hide();
                $('.finalist-overview').hide();
                $('.finalist-overlay-grid').hide();

                $('html,body').scrollTop(0);

                $('.participant-overlay-grid').fadeIn(500).addClass('open');
                $('.intro-section-container').fadeIn(500);
                $('.intro-section-container .participant').fadeIn(500);

                setTimeout(function () {
                    $('body').trigger('pagenavupdate');
                    $('html, body').animate({
                        scrollTop: $('.participant-container[data-id="' + scrollToTeam + '"]').offset().top - 50
                    }, 1000);
                }, 2000);

                $('body').trigger('pagenavupdate');
                $('.finalist-page .sideNavWrap').fadeIn();
                $('.finalist-page .closeNav').fadeIn();

            }
        },

        resetGranteesVideo: function () {
            $('.videoSection-container').empty();
            $('.videoSection-container').html('<div class="videoSection-video" data-youtube="NKVapXqOILE" data-autoplay="0" data-poster="img/grantees-video.jpg"></div>');
        },

        renderTemplates: function (data) {

            data.sort(function (a, b) {
                return a.id - b.id;
            });

            $.each(data, function (i, r) {
                data[i].total = data.length;
            });

            $('.finalistContainer').append(templates.finalists({steps: data}));

            $('.granteeContainer').append(templates.grantees({steps: data}));

        },

        finalistOverlays: function (data) {

            var module = this;


            $('body').trigger('pagenavupdate');

            $(document).on('click', '.close-overlay', function () {

                $('.navbarWrap').removeClass('hidden').trigger('pagenavupdate');

                //stop any videos
                $('.finalist-container .finalist-video .video-iframe-container').each(function () {
                    $(this).html('');
                });

                if ($('.finalist-overlay-grid').hasClass('open')) {

                    $('.finalist-overlay-grid').fadeOut().removeClass('open');
                    $('.finalist-container').removeClass('active');

                    $('body').trigger('pagenavupdate').removeClass('locked');
                }

            });

            $(document).on('click', '.go-to-finalists', function (e) {
                e.preventDefault();

                module.resetGranteesVideo();

                $('.navbarWrap').addClass('hidden').trigger('pagenavupdate');
                $('body').addClass('locked');
                var parId = $(this).parents('.finalist-col').attr('data-id');
                var videoUrl = $('.pageContainer.active .finalist-container[data-finalist="' + parId + '"] .finalist-video').attr('video-url');

                $('.pageContainer.active .finalist-overlay-grid').fadeIn(500).addClass('open');
                $('.pageContainer.active .finalist-container[data-finalist="' + parId + '"]').addClass('active');
                $('.pageContainer.active .finalist-overlay-grid .sideNav .currentCount').html(parId);

                $('.pageContainer.active .finalist-container[data-finalist="' + parId + '"]').find('.video-iframe-container').html('<div class="custom-video" data-youtube="' + videoUrl + '" data-autoplay="0"></div>');

                $('.sideNav .currentCount').html(parId);
                $('.finalist-page .sideNavWrap').fadeIn();
                $('.finalist-page .closeNav').fadeIn();
            });

        }
    }
});

